<div class="dialog">
  <button mat-raised-button color="primary" [cdkCopyToClipboard]="url">
    Get Link
  </button>
  <button mat-raised-button color="primary" (click)="settleBet()">
    Settle
  </button>
  <button mat-raised-button color="primary" (click)="deleteBet()">
    Delete
  </button>
</div>
