<mat-card class="card">
  <mat-card-header>
    <div mat-card-avatar class="header-image"></div>
    <mat-card-title>Sam Greene</mat-card-title>
    <mat-card-subtitle>Full Stack Developer</mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image src="assets/images/cats.jpg" alt="Photo of dorm" />
  <mat-card-content>
    <p>
      Hey there. I'm just working on this website for fun. Everything is on my
      github if you want to check it out. I'm using Angular for the frontend,
      AWS CDK to build the infrastructure in AWS, and AWS for l everything else.
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button
      mat-button
      onclick="location.href='http://github.com/samsterlicious'"
    >
      <mat-icon svgIcon="github"></mat-icon> Github
    </button>
    <button
      mat-button
      onclick="location.href='https://www.linkedin.com/in/sam-greene-9160847a/'"
    >
      <mat-icon svgIcon="linkedin"></mat-icon> Linked
    </button>
    <a mat-button href="mailto:pgreene864@gmail.com">
      <mat-icon svgIcon="gmail"></mat-icon> Gmail
    </a>
  </mat-card-actions>
</mat-card>
