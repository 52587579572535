 <mat-toolbar color="primary"> 
  <span>Mauldin</span>
  <span class="spacer"></span>
  <span *ngIf="isLoggedIn$ | async; else notLoggedIn">
  <span  class="welcome-span">Welcome {{ name }}</span>
  <button
    mat-icon-button
    *ngIf="isLoggedIn$ | async;"
    [matMenuTriggerFor]="menu"
    aria-label="name menu"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu"> 
    <button (click)="signOut()" class="mat-menu-item">
      <span>Logout</span>
    </button>
  </mat-menu>
  </span>
  <ng-template #notLoggedIn>
  <button 
    class="mat-icon-button icon-button"
    color="primary"
    aria-label="Sign in button"
    (click)="signIn()"
  >
    Sign in
  </button>
</ng-template>
</mat-toolbar> 